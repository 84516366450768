import React, { Component } from 'react';
import bg from "../assets/DBA-ingénierie-et-business-ISEN-Lille.jpg";
import StepNavigation from '../components/StepNavigation';
import PersonalData from '../components/Steps/PersonalData';
import WorkExperience from '../components/Steps/WorkExperience';
import EducationBackground from '../components/Steps/EducationBackground';
import AdditionalInformation from '../components/Steps/AdditionalInformation';
import FileToProvide from '../components/Steps/FileToProvide';
import NavigationBottom from '../components/NavigationBottom';
import styled from "styled-components";
import '../index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import UploadService from '../services/upload-files.service';
import * as emailjs from 'emailjs-com';


const { v4: uuidv4 } = require('uuid');
const today = new Date();
const todayDate = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();

const initialState = {
    values: {
        currentStep: 0,
        avatar: '',
        firstname: "",
        lastname: "",
        gender: "",
        nationality_pass: "",
        birthdate: "",
        birthplace: "",
        birth_country: "",
        passport_number: "",
        address: {
            office_address: "",
            zipcode_office: "",
            office_city: "",
            office_country: "",
            office_phone: "",
            office_fax: "",
            home_address: "",
            zipcode_home: "",
            home_city: "",
            home_country: "",
            home_phone: "",
            home_fax: ""
        },
        email: "",
        mobile: "",
        contact: "",
        contact_name: "",
        contact_mobile: "",
        work:{
            experience_work: "",
            experience_management: "",
            current_employer: "",
            current_employer_activity: "",
            job_title: "",
            experience_company: "",
            subordinate_number: "",
            responsibilities: "",
            employment_experience:[{
                from_experience: "",
                to_experience: "",
                organisation_experience: "",
                position_experience: "",
                responsibility_experience: "",
            }]
        },
        education: [{
            diploma: "",
            date_start: "",
            date_end: "",
            job_title_major: "",
            qualification_obtained: "",
            scholarship:"",
            awarding_institution:"",
            year:""
        }],
        addition:{
            org_sponsor:"",
            judgment:"",
            circumstances:""
        },
        file: {
            curriculum_vitae: '',
            graduate_degree: '',
            master_business_admin: '',
            passport: '',
            other_file: '',
            other_file_1: '',

        },
        dateSignature: todayDate,
        handleSubmit: "",
        createdby : "Registration form test"
    },
    errors: {
        avatar: false,
        firstname: false,
        lastname: false,
        //gender: false,
        nationality_pass: false,
        birthdate: false,
        birthplace: false,
        birth_country: false,
        passport_number: false,
        address: {
            office_address: false,
            zipcode_office: false,
            office_city: false,
            office_country: false,
            office_phone: false,
            //office_fax: false,
            home_address: false,
            zipcode_home: false,
            home_city: false,
            home_country: false,
            home_phone: false,
            //home_fax: false
        },
        email: false,
        mobile: false,
        //contact: false,
        contact_name: false,
        contact_mobile: false,
        work:{
            experience_work:false,
            experience_management:false,
            current_employer:false,
            current_employer_activity:false,
            job_title:false,
            experience_company:false,
            subordinate_number:false,
            responsibilities:false,
            employment_experience:[{
                from_experience: false,
                to_experience: false,
                organisation_experience: false,
                position_experience: false,
                responsibility_experience: false,
            }]
        },
        education: [{
            diploma: false,
            date_start: false,
            date_end: false,
            job_title_major: false,
            qualification_obtained: false,
            scholarship:false,
            awarding_institution:false,
            year:false
        }],
        addition:{
            org_sponsor:false,
            judgment:false,
            circumstances:false,
        },
        file: {
            curriculum_vitae: false,
            graduate_degree: false,
            master_business_admin: false,
            passport: false,
            other_file: false,
            other_file_1: false,
        },
        //dateSignature: false
    },
    currentFile: undefined,
    progress: 0,
    message: "",
    fileInfos: [],
};

class Home extends Component {

    constructor(props) {
        super(props);
        this.handleChangeFile = this.handleChangeFile.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = initialState;
        this.loading = true;
    }

    nextPage = event => {
        event.preventDefault();

        const errors = this.validateValue();
        console.log(Object.keys(errors).length, errors)
        if (Object.keys(errors).length > 0) {
            this.setState(() => ({
                errors: errors,
            }));
        } else {
            this.setState(state => ({
                values: {
                    ...this.state.values,
                    currentStep: state.values.currentStep + 1,
                },
                errors: errors,
            }));
        }
    };
    previousStep = () => {
        this.setState(state => ({
            values: {
                ...state.values,
                currentStep: state.values.currentStep - 1,
            },
        }));
    };


    /*async componentDidMount(){
        const email = JSON.stringify(this.state.values.email);
        const url = "https://nestorai-api-d01.azurewebsites.net/registrations/by_email/"+{email}
        const response = await fetch(url);
        const data = await response.json();
        this.setState({values: data.data[0], loading:false});
        console.log(data.data[0]);
      }*/
    handleSubmit = (event) => {
        event.preventDefault();
        alert('A form was submitted');
        console.log(this.state.values);
        const errors = this.validateValue();
        console.log(Object.keys(errors).length, errors)
        if (Object.keys(errors).length > 0) {
            this.setState(() => ({
                errors: errors,
            }));
        } else {
            this.setState(state => ({
                values: {
                    ...this.state.values,
                },
                errors: errors,
            }));
        }

        let body = this.state.values; // recup de toutes les valeurs du formulaire dans une variable body, qui sera utilisée pour les envois API
        let files = this.state.values.file; // recup des infos sur les fichiers dans une nouvelle variable
        body.file = {}; // on vide la partie fichier de ce que l'on veut envoyer à l'API pour ne pas envoyer complètement les fichiers.

        //POST

        // idéalement ce POST doit être fait plus tôt dans les soumissions,
        // => cela nous permettrait d'obtenir un ID pour le candidat et l'utiliser ensuite pour les fichiers
        // et ainsi ne pas utiliser la fonction UUID()


        console.log("values to be posted:");
        console.log(body);

        let currentFile, currentFileName, currentFileType;
        let files_array = Object.entries(files); // on transforme notre objet qui contient les fichiers dans un tableau dans lequel on va boucler
        let submissionId = uuidv4(); // on génère un uuid pour la soumission // potentiellement a revoir
        console.log("files_array: ")
        console.log(files_array);

        for (let i = 0; i < files_array.length; i++){

            currentFile = files_array[i][1];    // on récup le fichier dans le tableau
            if(!currentFile) continue;          // pas de fichier, on continue dans la boucle

            currentFileType = files_array[i][0];    // type de fichier => correspond au champ input
            currentFileName = submissionId + "_" + currentFileType + "." + currentFile.name.split('.').pop(); // ici on change le nom en utilisant un UUID et on ajoute l'extension d'origine

            console.log("Handling File: " + i + ":");
            console.log(" > Filename : " + currentFileName + "(orignal: " + currentFile.name + ")");

                currentFileType = files_array[i][0];    // type de fichier => correspond au champ input
                currentFileName = submissionId + "_" + currentFileType + "." + currentFile.name.split('.').pop(); // ici on change le nom en utilisant un UUID et on ajoute l'extension d'origine

                console.log("Handling File: " + i + ":");
                console.log(" > Filename : " + currentFileName + "(orignal: " + currentFile.name + ")");

            // on modifie la valeur dans le JSON envoyé au service
            body.file[currentFileType] = {
                "filename": currentFileName,
                "type": currentFile.type,
                "size": currentFile.size
            };

            UploadService.upload(currentFileName, currentFile, (event) => {
                console.log(event);
                this.setState({

                    // il faudrait gérer la progression de l'upload en affichant l'info sur le formulaire
                    // tout en verrouillant le formulaire pendant l'envoi => éviter double envoi
                    progress: Math.round((100 * event.loaded) / event.total),
                });
            });
            this.setState(state => ({values: {...state.values, file: {...this.state.values.file}},}),
                () => this.setState({ errors: this.validateValue() })
            );
        }

        //let registrationId;
        fetch('https://nestorai-api-d01.azurewebsites.net/registrations', {
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin':'*',
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'mode': 'cors',
                'x-api-key':'',
            },
            // We convert the React state to JSON and send it as the POST body
            body: JSON.stringify(body),
        })
            .then(function(response) {
                console.log(response);
                return response.json();
            })

            /*.then(function(json) {
                registrationId= json.data._id;
                console.log(registrationId);
                //PUT => /registrations/{registrationId}
                fetch('https://nestorai-api-d01.azurewebsites.net/registrations/'+registrationId, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'mode': 'cors'
                    },
                    body: JSON.stringify(body),
                })
                    .then(function(response) {
                        console.log(response)
                        return response.json();
                    })
                    .catch(function(res){ console.log(res) });

            })*/
            .catch(function(err){ console.log(err) });

            emailjs
                .sendForm('service_a9ke4mo', 'template_7tsv0rp', event.target, 'user_Mnvg9kKdsROiMve3GEGeT')
                .then((response) => {
                    console.log('SUCCESS!', response.status, response.text);
                    }, (error) => {
                    console.log('FAILED...',error.text);
                });
    }

    onChange = event => {
        const { name, value } = event.target;

        console.log(name, value)
        this.setState(state => ({values: {...state.values, [name]: value,},}),
            () => this.setState({ errors: this.validateValue() })
        );

    };
    handleChangeAddress = event => {
        const { name, value } = event.target;

        console.log(name, value)
        this.setState(state => ({values: {...state.values, address: {...this.state.values.address, [name]: value}},}),
            () => this.setState({ errors: this.validateValue() })
        );

    };
    handleChangeWork = event => {
        const { name, value } = event.target;

        console.log(name, value)
        console.log(event.target)
        this.setState(state => ({values: {...state.values, work: {...this.state.values.work, [name]: value}},}),
            () => this.setState({ errors: this.validateValue() })
        );

    };
    setModalFormWorkExperience = workValues =>{
        this.setState({values: {...this.state.values, work: {...this.state.values.work, employment_experience:workValues}},})
    };
    setModalForm = educationValues =>{
        this.setState({values: {...this.state.values, education:educationValues}})
    };
    handleChangeAddition = event => {
        const { name, value } = event.target;

        console.log(name, value)
        this.setState(state => ({values: {...state.values, addition: {...this.state.values.addition, [name]: value}},}),
            () => this.setState({ errors: this.validateValue() })
        );

    };

    // UPLOAD FILES
    handleChangeFile = event => {
        const { name, value } = event.target;

        console.log(name, value)
        this.setState(state => ({values: {...state.values, file: {...this.state.values.file, [name]: value}},}),
            () => this.setState({ errors: this.validateValue() })
        );
    };
    validateValue = () => {
        const { values } = this.state;
        const errors = {};
        switch (values.currentStep) {

            case 0:
                if (!values.avatar) {
                    errors.avatar = 'Required image';
                }
                if (values.firstname.length < 1) {
                    errors.firstname = 'Required field';
                }
                if (values.lastname.length < 1) {
                    errors.lastname = 'Required field';
                }
                if (!values.nationality_pass) {
                    errors.nationality_pass = 'Required choice';
                }
                if (values.birthdate.length < 1) {
                    errors.birthdate = 'Required field';
                }
                if (values.birthplace.length < 1) {
                    errors.birthplace = 'Required field';
                }
                if (values.birth_country.length < 1) {
                    errors.birth_country = 'Required field';
                }
                if (values.passport_number.length < 1) {
                    errors.passport_number = 'Required field';
                }
                if (values.address.office_address?.length < 1) {
                    errors.office_address = 'Required field';
                }
                if (values.address.zipcode_office?.length < 1) {
                    errors.zipcode_office = 'Required field';
                }
                if (values.address.office_city?.length < 1) {
                    errors.office_city = 'Required field';
                }
                if (values.address.office_country?.length < 1) {
                    errors.office_country = 'Required field';
                }
                if (values.address.office_phone?.length < 1) {
                    errors.office_phone = 'Required field';
                }
                if (values.address.office_fax?.length < 1) {
                    //errors.office_fax = 'Required field';
                }
                if (values.address.home_address?.length < 1) {
                    errors.home_address = 'Required field';
                }
                if (values.address.zipcode_home?.length < 1) {
                    errors.zipcode_home = 'Required field';
                }
                if (values.address.home_city?.length < 1) {
                    errors.home_city = 'Required field';
                }
                if (values.address.home_country?.length < 1) {
                    errors.home_country = 'Required field';
                }
                if (values.address.home_phone?.length < 1) {
                    errors.home_phone = 'Required field';
                }
                if (values.address.home_fax?.length < 1) {
                    //errors.home_fax = 'Required field';
                }
                const emailFormat = /[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}/gim;
                if (values.email.match(emailFormat) === null) {
                    errors.email = 'Invalid email address';
                }
                if (values.mobile.length < 1) {
                    errors.mobile = 'Required field';
                }
                if (values.contact_name.length < 1) {
                    errors.contact_name = 'Required field';
                }
                if (values.contact_mobile.length < 1) {
                    errors.contact_mobile = 'Required field';
                }
                break;
            case 1:
                if (!values.work.experience_work) {
                    errors.experience_work = 'Required choice';
                }
                if (!values.work.experience_management) {
                    errors.experience_management = 'Required choice';
                }
                if (values.work.current_employer?.length < 1) {
                    errors.current_employer = 'Required field';
                }
                if (values.work.current_employer_activity?.length < 1) {
                    errors.current_employer_activity = 'Required field';
                }
                if (values.work.job_title?.length < 1) {
                    errors.job_title = 'Required field';
                }
                if (!values.work.experience_company) {
                    errors.experience_company = 'Required choice';
                }
                if (values.work.subordinate_number?.length < 1) {
                    errors.subordinate_number = 'Required field';
                }
                if (values.work.responsibilities?.length < 1) {
                    errors.responsibilities = 'Required field';
                }
                break;
            case 2:
                break;
            case 3:
                if ((values.addition.circumstances?.length < 1) && (values.addition.judgment === "Yes" )){
                    errors.circumstances = 'Required field';
                }
                break;
            case 4:
                if (!values.file.curriculum_vitae) {
                    errors.curriculum_vitae = 'Required file';
                }
                if (!values.file.graduate_degree) {
                    //errors.graduate_degree = 'Required file';
                }
                if (!values.file.master_business_admin) {
                    //errors.master_business_admin = 'Required file';
                }
                if (!values.file.passport) {
                    errors.passport = 'Required file';
                }
                if (!values.file.other_file) {
                    //errors.other_file = 'Required file';
                }
                if (values.dateSignature.length < 1){
                }
                break;
            default:
                break;
        }
        return errors;
    };
    render()
    {
        const {errors, values} = this.state;
        return (
            <div>
                <div>
                    <StyledDiv>
                        <StyledTitle> Application Form</StyledTitle>
                    </StyledDiv>
                    <Form method="POST">
                        <StepNavigation currentStep={values.currentStep}  />
                        {values.currentStep === 0 && (
                            <PersonalData
                                values={values}
                                errors={errors}
                                onChange={this.onChange}
                                handleChangeAddress={this.handleChangeAddress}
                            />
                        )}
                        {values.currentStep === 1 && (
                            <WorkExperience
                                values={values}
                                errors={errors}
                                handleChangeWork={this.handleChangeWork}
                                setModalFormWorkExperience={this.setModalFormWorkExperience}
                            />
                        )}
                        {values.currentStep === 2 && (
                            <EducationBackground
                                values={values}
                                errors={errors}
                                onChange={this.onChange}
                                setModalForm={this.setModalForm}
                            />
                        )}
                        {values.currentStep === 3 && (
                            <AdditionalInformation
                                values={values}
                                errors={errors}
                                handleChangeAddition={this.handleChangeAddition}
                            />
                        )}
                        {values.currentStep === 4 && (
                            <FileToProvide
                                values={values}
                                errors={errors}
                                onChange = {this.onChange}
                                handleChangeFile={this.handleChangeFile}
                            />
                        )}
                        <NavigationBottom
                            currentStep={values.currentStep}
                            previousStep={this.previousStep}
                            nextPage={this.nextPage}
                            disabled={!this.state.values.file}
                            onSubmit={this.handleSubmit}
                        />
                    </Form>
                </div>
            </div>
        );
    }
}

export default Home;

const StyledDiv = styled.div`
  background: url(${bg}) no-repeat ;
  background-size: cover;
  height: 40vh;
  width: 100%;
`;

const StyledTitle = styled.h1`
   position: absolute;
    left: 10%;
    right: 10%;
    top: 12%;
    bottom: auto;
    background: #8080807a;
    text-align: center;
    color: white;
    font-weight: 300;
    font-size: 6rem;
    line-height: calc(30vh);
    letter-spacing: .2rem;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 12%;
        right: 12%;
        bottom: 0;
        border-radius: 40%;
        z-index: -1;
        box-shadow: 0 0 100px 0 rgba(#000, .8);
    };
     @media (max-width: 1366px) {
    top: 12%;
  }
  @media (max-width: 1024px) {
    top: 8%;
  }
  @media (max-width: 768px) {
    font-size: 4rem;
    top: 8%;
  }
  @media (max-width: 375px) {
    font-size: 3.5rem;
    line-height: 1.5em;
    top: 13%;
  }`
;

const Form = styled.form`
    display: block;
    margin-top: 1.5%;
    margin-left: 15%;
    margin-right: 15%;
    margin-bottom: 2%;
    @media (max-width: 1366px) {
    margin-left: 5%;
    margin-right: 5%;
    }
`;
