import React from 'react';
import styled from "styled-components";
import { Row, Col} from "react-bootstrap";
import PropTypes from 'prop-types';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
    Button,
    Container,
    Modal,
    ModalHeader,
    ModalBody,
    FormGroup,
    ModalFooter,
} from "reactstrap";

const data = [
];
const data_academic = [
];

//const EducationBackground = ({ values, errors, onChange }) =>
class EducationBackground extends React.Component{

    state = {
        data: data,
        data_academic: data_academic,
        modalUpdate: false,
        modalUpdate_academic: false,
        modalInsert: false,
        modalInsert_academic: false,
        form: {
            id: "",
            diploma: "",
            date_start: "",
            date_end: "",
            job_title_major: "",
            qualification_obtained: "",
        },
        form_academic:{
            scholarship:"",
            awarding_institution:"",
            year:""
        }
    };

        showModalUpdate = (education) => {
        this.setState({
            form: education,
            modalUpdate: true,
        });
        };
        showModalUpdate_academic = (academic) => {
            this.setState({
                form_academic: academic,
                modalUpdate_academic: true,
            });
        };

        closeModalUpdate = () => {
        this.setState({ modalUpdate: false });
        };
        closeModalUpdate_academic = () => {
        this.setState({ modalUpdate_academic: false });
        };

        showModalInsert = () => {
        this.setState({
            modalInsert: true,
        });
        };
        showModalInsert_academic = () => {
        this.setState({
            modalInsert_academic: true,
        });
        };

        closeModalInsert = () => {
        this.setState({ modalInsert: false });
        };
        closeModalInsert_academic = () => {
        this.setState({ modalInsert_academic: false });
        };

        edit = (education) => {
        let counter = 0;
        let array = this.state.data;
            // eslint-disable-next-line array-callback-return
            array.map((register) => {
            if (education.id === register.id) {
                array[counter].diploma = education.diploma;
                array[counter].date_start = education.date_start;
                array[counter].date_end = education.date_end;
                array[counter].job_title_major = education.job_title_major;
                array[counter].qualification_obtained = education.qualification_obtained;
            }
                counter++;
        });
        this.setState({ data: array, modalUpdate: false });
    };
        edit_academic = (academic) => {
        let counter = 0;
        let array = this.state.data_academic;
            // eslint-disable-next-line array-callback-return
            array.map((register) => {
            if (academic.id === register.id) {
                array[counter].scholarship = academic.scholarship;
                array[counter].awarding_institution = academic.awarding_institution;
                array[counter].year = academic.year;

            }
                counter++;
        });
        this.setState({ data_academic: array, modalUpdate_academic: false });
    };

        delete = (education) => {
        const option = window.confirm("Are you sure you want to delete the item "+education.id);
        if (option === true) {
            let counter = 0;
            let array = this.state.data;
            // eslint-disable-next-line array-callback-return
            array.map((register) => {
                if (education.id === register.id) {
                    array.splice(counter, 1);
                }
                counter++;
            });
            this.setState({ data: array, modalUpdate: false });
        }
    };
        delete_academic = (academic) => {
        const option = window.confirm("Are you sure you want to delete the item "+academic.id);
        if (option === true) {
            let counter = 0;
            let array = this.state.data_academic;
            // eslint-disable-next-line array-callback-return
            array.map((register) => {
                if (academic.id === register.id) {
                    array.splice(counter, 1);
                }
                counter++;
            });
            this.setState({ data_academic: array, modalUpdate_academic: false });
        }
    };

        insert= ()=>{
        let valueNew= {...this.state.form};
            valueNew.id=this.state.data.length+1;
        let list= this.state.data;
            list.push(valueNew);
            // console.log(list);
            this.props.setModalForm(list);
        this.setState({ modalInsert: false, data: list });
    }
        insert_academic= ()=>{
        let valueNew= {...this.state.form_academic};
            valueNew.id=this.state.data_academic.length+1;
        let list_academic= this.state.data_academic;
        list_academic.push(valueNew);
        this.props.setModalForm(list_academic);
        this.setState({ modalInsert_academic: false, data_academic: list_academic });
    }

        handleChange = (e) => {
            // console.log(e.target.value);
            this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value,
            },
            form_academic:{
                ...this.state.form_academic,
                [e.target.name]: e.target.value,
            }
            });
        };
    render()
    {
        return (
            <div>
                <StyledWrap>
                    <h2> C. Education Background</h2>
                </StyledWrap>
                <FormContent>
                    <Row>
                        <Col md={12}>
                            <h6 className="text-center">Please complete the following information regarding
                                every university or college you have attended in turn <br/>
                                (Include Bachelor and Master. If necessary, please use additional slots)</h6>
                            <>
                                <Container>
                                    <br/>
                                    <Button className="button-add" onClick={() => this.showModalInsert()}>Add</Button>
                                    <br/>
                                    <br/>
                                    <table className="table table-bordered text-center">
                                        <thead>
                                        <tr className="tire">
                                            <th>N°</th>
                                            <th>University/Country</th>
                                            <th>From (Year)</th>
                                            <th>To (Year)</th>
                                            <th>Major</th>
                                            <th>Qualification Obtained</th>
                                            <th>Action</th>
                                        </tr>
                                        </thead>

                                        <tbody>
                                        {this.state.data.map((education) => (
                                            <tr key={education.id}>
                                                <td>{education.id}</td>
                                                <td>{education.diploma}</td>
                                                <td>{education.date_start}</td>
                                                <td>{education.date_end}</td>
                                                <td>{education.job_title_major}</td>
                                                <td>{education.qualification_obtained}</td>
                                                <td>
                                                    <Button color="primary"
                                                        onClick={() => this.showModalUpdate(education)}>Edit</Button>
                                                    {" "}
                                                    <Button color="danger"
                                                            onClick={() => this.delete(education)}>delete</Button>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </Container>

                                <Modal isOpen={this.state.modalUpdate}>
                                    <ModalHeader>
                                        <div><h3>Edit Information</h3></div>
                                    </ModalHeader>

                                    <ModalBody>
                                        <FormGroup>
                                            <label>
                                                N°:
                                            </label>

                                            <input
                                                className="form-control"
                                                readOnly
                                                type="text"
                                                value={this.state.form.id}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <label>
                                                University/Country:
                                            </label>
                                            <input
                                                className="form-control"
                                                name="diploma"
                                                type="text"
                                                onChange={this.handleChange}
                                                value={this.state.form.diploma}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <label>
                                                From(Year):
                                            </label>
                                            <input
                                                className="form-control"
                                                name="date_start"
                                                type="number"
                                                onChange={this.handleChange}
                                                value={this.state.form.date_start}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <label>
                                                To(Year):
                                            </label>
                                            <input
                                                className="form-control"
                                                name="date_end"
                                                type="number"
                                                onChange={this.handleChange}
                                                value={this.state.form.date_end}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <label>
                                                Major:
                                            </label>
                                            <input
                                                className="form-control"
                                                name="job_title_major"
                                                type="text"
                                                onChange={this.handleChange}
                                                value={this.state.form.job_title_major}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <label>
                                                Qualification Obtained:
                                            </label>
                                            <input
                                                className="form-control"
                                                name="qualification_obtained"
                                                type="text"
                                                onChange={this.handleChange}
                                                value={this.state.form.qualification_obtained}
                                            />
                                        </FormGroup>
                                    </ModalBody>

                                    <ModalFooter>
                                        <Button
                                            color="primary"
                                            onClick={() => this.edit(this.state.form)}
                                        >
                                            Update
                                        </Button>
                                        <Button
                                            color="danger"
                                            onClick={() => this.closeModalUpdate()}
                                        >
                                            Cancel
                                        </Button>
                                    </ModalFooter>
                                </Modal>


                                <Modal isOpen={this.state.modalInsert}>
                                    <ModalHeader>
                                        <div><h3>Insert Information</h3></div>
                                    </ModalHeader>

                                    <ModalBody>
                                        <FormGroup>
                                            <label>
                                                N°:
                                            </label>

                                            <input
                                                className="form-control"
                                                readOnly
                                                type="text"
                                                value={this.state.data.length + 1}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <label>
                                                University/Country:
                                            </label>
                                            <input
                                                className="form-control"
                                                name="diploma"
                                                type="text"
                                                onChange={this.handleChange}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <label>
                                                From(Year):
                                            </label>
                                            <input
                                                className="form-control"
                                                name="date_start"
                                                type="number"
                                                onChange={this.handleChange}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <label>
                                                To(Year):
                                            </label>
                                            <input
                                                className="form-control"
                                                name="date_end"
                                                type="number"
                                                onChange={this.handleChange}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <label>
                                                Major:
                                            </label>
                                            <input
                                                className="form-control"
                                                name="job_title_major"
                                                type="text"
                                                onChange={this.handleChange}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <label>
                                                Qualification Obtained:
                                            </label>
                                            <input
                                                className="form-control"
                                                name="qualification_obtained"
                                                type="text"
                                                onChange={this.handleChange}
                                            />
                                        </FormGroup>
                                    </ModalBody>

                                    <ModalFooter>
                                        <Button
                                            color="primary"
                                            onClick={() => this.insert()}
                                        >
                                            Insert
                                        </Button>
                                        <Button
                                            className="btn btn-danger"
                                            onClick={() => this.closeModalInsert()}
                                        >
                                            Cancel
                                        </Button>
                                    </ModalFooter>
                                </Modal>
                            </>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <h6 className="text-center list_scholarships_academic">Please list any prizes, or similar distinctions in
                                scholarships gained during your academic career</h6>
                            <>
                                <Container>
                                    <br/>
                                    <Button className="button-add" onClick={() => this.showModalInsert_academic()}>Add</Button>
                                    <br/>
                                    <br/>
                                    <table className="table table-bordered text-center">
                                        <thead>
                                        <tr className="tire">
                                            <th>N°</th>
                                            <th>Name of the Price / Scholarship</th>
                                            <th>Awarding Institution</th>
                                            <th>Year</th>
                                            <th>Action</th>
                                        </tr>
                                        </thead>

                                        <tbody>
                                        {this.state.data_academic.map((academic) => (
                                            <tr key={academic.id}>
                                                <td>{academic.id}</td>
                                                <td>{academic.scholarship}</td>
                                                <td>{academic.awarding_institution}</td>
                                                <td>{academic.year}</td>
                                                <td>
                                                    <Button color="primary"
                                                            onClick={() => this.showModalUpdate_academic(academic)}>Edit</Button>
                                                    {" "}
                                                    <Button color="danger"
                                                            onClick={() => this.delete_academic(academic)}>delete</Button>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </Container>

                                <Modal isOpen={this.state.modalUpdate_academic}>
                                    <ModalHeader>
                                        <div><h3>Edit Information</h3></div>
                                    </ModalHeader>

                                    <ModalBody>
                                        <FormGroup>
                                            <label>
                                                No°:
                                            </label>

                                            <input
                                                className="form-control"
                                                readOnly
                                                type="text"
                                                value={this.state.form_academic.id}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <label>
                                                Name of the Price / Scholarship:
                                            </label>
                                            <input
                                                className="form-control"
                                                name="scholarship"
                                                type="text"
                                                onChange={this.handleChange}
                                                value={this.state.form_academic.scholarship}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <label>
                                                Awarding Institution:
                                            </label>
                                            <input
                                                className="form-control"
                                                name="awarding_institution"
                                                type="text"
                                                onChange={this.handleChange}
                                                value={this.state.form_academic.awarding_institution}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <label>
                                                Year:
                                            </label>
                                            <input
                                                className="form-control"
                                                name="year"
                                                type="number"
                                                onChange={this.handleChange}
                                                value={this.state.form_academic.year}
                                            />
                                        </FormGroup>
                                    </ModalBody>

                                    <ModalFooter>
                                        <Button
                                            color="primary"
                                            onClick={() => this.edit_academic(this.state.form_academic)}
                                        >
                                            Update
                                        </Button>
                                        <Button
                                            color="danger"
                                            onClick={() => this.closeModalUpdate_academic()}
                                        >
                                            Cancel
                                        </Button>
                                    </ModalFooter>
                                </Modal>


                                <Modal isOpen={this.state.modalInsert_academic}>
                                    <ModalHeader>
                                        <div><h3>Insert Information</h3></div>
                                    </ModalHeader>

                                    <ModalBody>
                                        <FormGroup>
                                            <label>
                                                N°:
                                            </label>

                                            <input
                                                className="form-control"
                                                readOnly
                                                type="text"
                                                value={this.state.data_academic.length + 1}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <label>
                                                Name of the Price / Scholarship:
                                            </label>
                                            <input
                                                className="form-control"
                                                name="scholarship"
                                                type="text"
                                                onChange={this.handleChange}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <label>
                                                Awarding Institution:
                                            </label>
                                            <input
                                                className="form-control"
                                                name="awarding_institution"
                                                type="text"
                                                onChange={this.handleChange}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <label>
                                                Year:
                                            </label>
                                            <input
                                                className="form-control"
                                                name="year"
                                                type="number"
                                                onChange={this.handleChange}
                                            />
                                        </FormGroup>
                                    </ModalBody>

                                    <ModalFooter>
                                        <Button
                                            color="primary"
                                            onClick={() => this.insert_academic()}
                                        >
                                            Insert
                                        </Button>
                                        <Button
                                            className="btn btn-danger"
                                            onClick={() => this.closeModalInsert_academic()}
                                        >
                                            Cancel
                                        </Button>
                                    </ModalFooter>
                                </Modal>
                            </>
                        </Col>
                    </Row>
                </FormContent>
            </div>
        );
    }
};

EducationBackground.propTypes = {
    values: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default EducationBackground;

const StyledWrap = styled.div`
    text-align: center;
    height: 80px;
    background: -webkit-linear-gradient(left, #046b65, #06084a);
    color: #fff;
    font-weight: bold;
    line-height: 80px;
`;

const FormContent = styled.div`
    padding: 5%;
    border: 1px solid #ced4da;
`;

