import React from 'react';
import styled from "styled-components";
import {Row, Col, FormGroup, FormCheck, FormControl, FormLabel} from "react-bootstrap";
import defaultImg from '../../../assets/avatar.png';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
export default function PersonalData(props) {
    const { values, onChange, handleChangeAddress, errors } = props;
//const PersonalData = ({ values, onChange }) => {
        const {
            avatar,
            firstname,
            lastname,
            gender,
            nationality_pass,
            birthdate,
            birthplace,
            birth_country,
            passport_number,
            office_address,
            zipcode_office,
            office_city,
            office_country,
            office_phone,
            office_fax,
            home_address,
            zipcode_home,
            home_city,
            home_country,
            home_phone,
            home_fax,
            email,
            mobile,
            contact,
            contact_name,
            contact_mobile,
        } = values;
        const {
            avatar: avatarError,
            firstname: firstnameError,
            lastname: lastnameError,
            //gender: genderError,
            nationality_pass: nationality_passError,
            birthdate: birthdateError,
            birthplace: birthplaceError,
            birth_country: birth_countryError,
            passport_number: passport_numberError,
            office_address: office_addressError,
            zipcode_office: zipcode_officeError,
            office_city: office_cityError,
            office_country: office_countryError,
            office_phone: office_phoneError,
            //office_fax: office_faxError,
            home_address: home_addressError,
            zipcode_home: zipcode_homeError,
            home_city: home_cityError,
            home_country: home_countryError,
            home_phone: home_phoneError,
            //home_fax: home_faxError,
            email: emailError,
            mobile: mobileError,
            //contact: contactError,
            contact_name: contact_nameError,
            contact_mobile: contact_mobileError,
        } = errors;



    const mainClassName = (error) =>
        classNames('custom-file-label', {
            invalid: error,
        });

    const onChangeAvatar = event => {
        const reader = new FileReader();
        reader.onload = event => {
            const body = { target: { name: 'avatar', value: event.target.result } };
            onChange(body);
        };
        reader.readAsDataURL(event.target.files[0]);
    };
    return (
        <div>
                <StyledWrap>
                    <h2> A. Personal Data</h2>
                </StyledWrap>
                <FormContent>
                    <StyledPhoto>
                        <Col md={{ span: 3 , offset: 5 }} className="space">
                            <img width="100%" src={avatar || defaultImg} alt="" />
                        </Col>
                        <Col md={{ span: 3 , offset: 5 }} className="space">
                            <input
                                type="file"
                                className="custom-file-input"
                                id="customFile"
                                onChange={onChangeAvatar}
                                accept=".png,.jpg, .jpeg, .webp"
                            />
                            <label className={mainClassName(avatarError)} htmlFor="customFile">
                                Choose avatar
                            </label>
                            {avatarError && <div className="invalid-feedback">{avatarError}</div>}
                        </Col>
                    </StyledPhoto>
                    <hr/>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <FormLabel>First Name</FormLabel>
                                <Input
                                       id="firstname"
                                       name="firstname"
                                       type="text"
                                       placeholder="..."
                                       value={firstname}
                                       error={firstnameError}
                                       onChange={onChange}
                                />
                                {firstnameError ? <div className="invalid-feedback">{firstnameError}</div> : null}
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <FormLabel>Last Name</FormLabel>
                                <Input
                                    id="lastname"
                                    name="lastname"
                                    type="text"
                                    placeholder="..."
                                    value={lastname}
                                    error={lastnameError}
                                    onChange={onChange}

                                />
                                {lastnameError ? <div className="invalid-feedback">{lastnameError}</div> : null}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <FormGroup>
                                <FormLabel htmlFor="inlineFormCustomSelectPref">Gender</FormLabel>
                                <Row>
                                    <Col md={4}>
                                        <FormCheck
                                            type="radio"
                                            className="my-1 mr-sm-2"
                                            id="male"
                                            name="gender"
                                            value="male"
                                            label="Male"
                                            checked={gender === 'male'}
                                            onChange={onChange}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <FormCheck
                                            type="radio"
                                            className="my-1 mr-sm-2"
                                            id="female"
                                            name="gender"
                                            value="female"
                                            label="Female"
                                            checked={gender === 'female'}
                                            onChange={onChange}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <FormLabel>Nationality</FormLabel>
                                <FormControl as="select" defaultValue={'None'} id="nationality_pass"
                                             name="nationality_pass"
                                             value={nationality_pass}
                                             onChange={onChange}
                                             error={nationality_passError }

                                >
                                    <option value="None">Choose...</option>
                                    <option value="Afghan">Afghan (Afghanistan)</option>
                                    <option value="Albanian">Albanian (Albania)</option>
                                    <option value="Algerian">Algerian (Algeria)</option>
                                    <option value="German">German (Germany)</option>
                                    <option value="American">American (United States)</option>
                                    <option value="Andorran">Andorran (Andorra)</option>
                                    <option value="Angolan">Angolan (Angola)</option>
                                    <option value="Antiguan-and-Barbuda">Antiguan-and-Barbuda (Antigua and Barbuda)</option>
                                    <option value="Argentina">Argentina (Argentina)</option>
                                    <option value="Armenian">Armenian (Armenia)</option>
                                    <option value="Australian">Australian (Australia)</option>
                                    <option value="Austrian">Austrian (Austria)</option>
                                    <option value="Azerbaijani">Azerbaijani (Azerbaijan)</option>
                                    <option value="Bahamian">Bahamian (Bahamas)</option>
                                    <option value="Bahraini">Bahraini (Bahrain)</option>
                                    <option value="Bangladeshi">Bangladeshi (Bangladesh)</option>
                                    <option value="Barbadian">Barbadian (Barbados)</option>
                                    <option value="Belgian">Belgian (Belgium)</option>
                                    <option value="Belizean">Belizean (Belize)</option>
                                    <option value="Beninese">Beninese (Benin)</option>
                                    <option value="Bhutanese">Bhutanese (Bhutan)</option>
                                    <option value="Belarusian">Belarusian (Belarus)</option>
                                    <option value="Burmese">Burmese (Burma)</option>
                                    <option value="Bissau-Guinean">Bissau-Guinean (Guinea-Bissau)</option>
                                    <option value="Bolivian">Bolivian (Bolivia)</option>
                                    <option value="Bosnian">Bosnian (Bosnia and Herzegovina)</option>
                                    <option value="Botswana">Botswana (Botswana)</option>
                                    <option value="Brazilian">Brazilian (Brazil)</option>
                                    <option value="British">British (United Kingdom)</option>
                                    <option value="Bruneian">Bruneian (Brunei)</option>
                                    <option value="Bulgarian">Bulgarian (Bulgaria)</option>
                                    <option value="Burkinabe">Burkinabe (Burkina)</option>
                                    <option value="Burundian">Burundian (Burundi)</option>
                                    <option value="Cambodian">Cambodian (Cambodia)</option>
                                    <option value="Cameroonian">Cameroonian (Cameroon)</option>
                                    <option value="Canadian">Canadian (Canada)</option>
                                    <option value="Cape Verdean">Cape Verdean (Cape Verde)</option>
                                    <option value="Central African">Central African (Central African)</option>
                                    <option value="Chilean">Chilean (Chile)</option>
                                    <option value="Chinese">Chinese (China)</option>
                                    <option value="Cypriot">Cypriot (Cyprus)</option>
                                    <option value="Colombian">Colombian (Colombia)</option>
                                    <option value="Comorian">Comorian (Comoros)</option>
                                    <option value="Congolese(Brazzaville)">Congolese (Congo-Brazzaville)</option>
                                    <option value="Congolese(Kinshasa)">Congolese (Congo-Kinshasa)</option>
                                    <option value="Cookian">Cookian (Cook Islands)</option>
                                    <option value="Costa Rican">Costa Rican (Costa Rica)</option>
                                    <option value="Croatian">Croatian (Croatia)</option>
                                    <option value="Cuban">Cuban (Cuba)</option>
                                    <option value="Danish">Danish (Denmark)</option>
                                    <option value="Djiboutian">Djiboutian (Djibouti)</option>
                                    <option value="Dominican">Dominican (Dominican Republic)</option>
                                    <option value="Dominican">Dominican (Dominica)</option>
                                    <option value="Egyptian">Egyptian (Egypt)</option>
                                    <option value="Emirati">Emirati (United Arab Emirates)</option>
                                    <option value="Equato-Guinean">Equato-Guinean (Equatorial Guinea)</option>
                                    <option value="Ecuadorian">Ecuadorian (Ecuador)</option>
                                    <option value="Eritrean">Eritrean (Eritrea)</option>
                                    <option value="Spanish">Spanish (Spain)</option>
                                    <option value="East Timorese">East Timorese (Timor-Leste)</option>
                                    <option value="Estonian">Estonian (Estonia)</option>
                                    <option value="Ethiopian">Ethiopian (Ethiopia)</option>
                                    <option value="Fijian">Fijian (Fiji)</option>
                                    <option value="Finnish">Finnish (Finland)</option>
                                    <option value="French">French (France)</option>
                                    <option value="Gabonese">Gabonese (Gabon)</option>
                                    <option value="Gambian">Gambian (Gambia)</option>
                                    <option value="Georgian">Georgian (Georgia)</option>
                                    <option value="Ghanaian">Ghanaian (Ghana)</option>
                                    <option value="Grenadian">Grenadian (Grenada)</option>
                                    <option value="Guatemalan">Guatemalan (Guatemala)</option>
                                    <option value="Guinean">Guinean (Guinea)</option>
                                    <option value="Guyanese">Guyanese (Guyana)</option>
                                    <option value="Haitian">Haitian (Haiti)</option>
                                    <option value="Hellenic">Hellenic (Greece)</option>
                                    <option value="Honduran">Honduran (Honduras)</option>
                                    <option value="Hungarian">Hungarian (Hungary)</option>
                                    <option value="Indian">Indian (India)</option>
                                    <option value="Indonesian">Indonesian (Indonesia)</option>
                                    <option value="Iraqi">Iraqi (Iraq)</option>
                                    <option value="Iranian">Iranian (Iran)</option>
                                    <option value="Irish">Irish (Ireland)</option>
                                    <option value="Icelandic">Icelandic (Iceland)</option>
                                    <option value="Israeli">Israeli (Israel)</option>
                                    <option value="Italian">Italian (Italy)</option>
                                    <option value="Ivorian">Ivorian (Ivory Coast)</option>
                                    <option value="Jamaican">Jamaican (Jamaica)</option>
                                    <option value="Japanese">Japanese (Japan)</option>
                                    <option value="Jordanian">Jordanian (Jordan)</option>
                                    <option value="Kazakhstanese">Kazakhstanese (Kazakhstan)</option>
                                    <option value="Kenyan">Kenyan (Kenya)</option>
                                    <option value="Kyrgyz">Kyrgyz (Kyrgyzstan)</option>
                                    <option value="Kiribatian">Kiribatian (Kiribati)</option>
                                    <option value="Kittitian and Neviathan">Kittitian and Neviathan (St. Kitts and Nevis)</option>
                                    <option value="Kuwaiti">Kuwaiti (Kuwait)</option>
                                    <option value="Laotian">Laotian (Laos)</option>
                                    <option value="Lesothan">Lesothan (Lesotho)</option>
                                    <option value="Latvian">Latvian (Latvia)</option>
                                    <option value="Lebanese">Lebanese (Lebanon)</option>
                                    <option value="Liberian">Liberian (Liberia)</option>
                                    <option value="Libyan">Libyan (Libya)</option>
                                    <option value="Liechtensteine">Liechtensteine (Liechtenstein)</option>
                                    <option value="Lithuanian">Lithuanian (Lithuania)</option>
                                    <option value="Luxembourgish">Luxembourgish (Luxembourg)</option>
                                    <option value="Macedonian">Macedonian (Macedonia)</option>
                                    <option value="Malaysian">Malaysian (Malaysia)</option>
                                    <option value="Malawian">Malawian (Malawi)</option>
                                    <option value="Maldivian">Maldivian (Maldives)</option>
                                    <option value="Malagasy">Malagasy (Madagascar)</option>
                                    <option value="Malian">Malaysian (Mali)</option>
                                    <option value="Maltese">Maltese (Malta)</option>
                                    <option value="Moroccan">Moroccan (Morocco)</option>
                                    <option value="Marshall">Marshallese (Marshall Islands)</option>
                                    <option value="Mauritian">Mauritian (Mauritius)</option>
                                    <option value="Mauritanian">Mauritanian (Mauritania)</option>
                                    <option value="Mexican">Mexican (Mexico)</option>
                                    <option value="Micronesian">Micronesian (Micronesia)</option>
                                    <option value="Moldovan">Moldovan (Moldova)</option>
                                    <option value="Monegasque">Monegasque (Monaco)</option>
                                    <option value="Mongolian">Mongolian (Mongolia)</option>
                                    <option value="Montenegrin">Montenegrin (Montenegro)</option>
                                    <option value="Mozambican">Mozambican (Mozambique)</option>
                                    <option value="Namibian">Namibian (Namibia)</option>
                                    <option value="Nauruan">Nauruan (Nauru)</option>
                                    <option value="Dutch">Dutch (Netherlands)</option>
                                    <option value="New Zealander">New Zealander (New Zealand)</option>
                                    <option value="Nepalese">Nepalese (Nepal)</option>
                                    <option value="Nicaraguan">Nicaraguan (Nicaragua)</option>
                                    <option value="Nigerian">Nigerian (Nigeria)</option>
                                    <option value="Nigerian">Nigerian (Niger)</option>
                                    <option value="Niuean">Niuean (Niue)</option>
                                    <option value="North Korean">North Korean (North Korea)</option>
                                    <option value="Norwegian">Norwegian (Norway)</option>
                                    <option value="Omani">Omani (Oman)</option>
                                    <option value="Ugandan">Ugandan (Uganda)</option>
                                    <option value="Uzbek">Uzbek (Uzbekistan)</option>
                                    <option value="Pakistani">Pakistani (Pakistan)</option>
                                    <option value="Palauan">Palauan (Palau)</option>
                                    <option value="Palestinian">Palestinian (Palestine)</option>
                                    <option value="Panamanian">Panamanian (Panama)</option>
                                    <option value="Papua New Guinean">Papua New Guinean (Papua New Guinea)</option>
                                    <option value="Paraguayan">Paraguayan (Paraguay)</option>
                                    <option value="Peruvian">Peruvian (Peru)</option>
                                    <option value="Filipino">Philippine (Philippines)</option>
                                    <option value="Polish">Polish (Poland)</option>
                                    <option value="Portuguese">Portuguese (Portugal)</option>
                                    <option value="Qatari">Qatari (Qatar)</option>
                                    <option value="Romanian">Romanian (Romania)</option>
                                    <option value="Russian">Russian (Russia)</option>
                                    <option value="Rwandan">Rwandan (Rwanda)</option>
                                    <option value="Saint Lucian">St. Lucian (Saint Lucia)</option>
                                    <option value="San Marino">San Marino (San Marino)</option>
                                    <option value="Saint Vincent and the Grenadines">St. Vincent and the Grenadines (Saint Vincent and the Grenadines)</option>
                                    <option value="Solomonese">Solomonese (Solomon Islands)</option>
                                    <option value="Salvadoran">Salvadorian (El Salvador)</option>
                                    <option value="Samoan">Samoan (Samoa)</option>
                                    <option value="Santomean">Santomean (Sao Tome and Principe)</option>
                                    <option value="Saudi">Saudi (Saudi Arabia)</option>
                                    <option value="Senegalese">Senegalese (Senegal)</option>
                                    <option value="Serbian">Serbian (Serbia)</option>
                                    <option value="Seychellois">Seychellois (Seychelles)</option>
                                    <option value="Sierra Leonean">Sierra Leonean (Sierra Leone)</option>
                                    <option value="Singaporean">Singaporean (Singapore)</option>
                                    <option value="Slovak">Slovakian (Slovakia)</option>
                                    <option value="Slovenian">Slovenian (Slovenia)</option>
                                    <option value="Somali">Somali (Somalia)</option>
                                    <option value="Sudanese">Sudanese (Sudan)</option>
                                    <option value="Sri Lankan">Sri Lankan (Sri Lanka)</option>
                                    <option value="South African">South African (South Africa)</option>
                                    <option value="South Korean">South Korean (South Korea)</option>
                                    <option value="South Sudanese">South Sudanese (South Sudan)</option>
                                    <option value="Swedish">Swedish (Sweden)</option>
                                    <option value="Swiss">Swiss (Switzerland)</option>
                                    <option value="Surinamese">Surinamese (Suriname)</option>
                                    <option value="Swazi">Swazi (Swaziland)</option>
                                    <option value="Syrian">Syrian (Syria)</option>
                                    <option value="Tajik">Tajik (Tajikistan)</option>
                                    <option value="Tanzanian">Tanzanian (Tanzania)</option>
                                    <option value="Chadian">Chadian (Chad)</option>
                                    <option value="Czech">Czech (Czechia)</option>
                                    <option value="Thai">Thai (Thailand)</option>
                                    <option value="Togolese">Togolese (Togo)</option>
                                    <option value="Tongan">Tongolese (Tonga)</option>
                                    <option value="Trinidadian">Trinidadian (Trinidad and Tobago)</option>
                                    <option value="Tunisian">Tunisian (Tunisia)</option>
                                    <option value="Turkmen">Turkmen (Turkmenistan)</option>
                                    <option value="Turkish">Turkish (Turkey)</option>
                                    <option value="Tuvaluan">Tuvaluan (Tuvalu)</option>
                                    <option value="Ukrainian">Ukrainian (Ukraine)</option>
                                    <option value="Uruguayan">Uruguayan (Uruguay)</option>
                                    <option value="Vanuatu">Vanuatuan (Vanuatu)</option>
                                    <option value="Vatican">Vatican (Vatican)</option>
                                    <option value="Venezuelan">Venezuelan (Venezuela)</option>
                                    <option value="Vietnamese">Vietnamese (Vietnam)</option>
                                    <option value="Yemeni">Yemeni (Yemen)</option>
                                    <option value="Zambian">Zambian (Zambia)</option>
                                    <option value="Zimbabwean">Zimbabwean (Zimbabwe)</option>
                                </FormControl>
                                {nationality_passError ? <div className="invalid-feedback">{nationality_passError}</div> : null}
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <FormLabel>ID No. or Passport No.</FormLabel>
                                <Input type="text"
                                       placeholder="..."
                                       id="passport_number"
                                       name="passport_number"
                                       value={passport_number}
                                       onChange={onChange}
                                       error={passport_numberError }
                                />
                                {passport_numberError ? <div className="invalid-feedback">{passport_numberError}</div> : null}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <FormGroup>
                                <FormLabel>Date of Birth</FormLabel>
                                <Input type="date"
                                       id="birthdate"
                                       name="birthdate"
                                       className="form-control"
                                       pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                                       value={birthdate}
                                       onChange={onChange}
                                       error={ birthdateError}

                                />
                                {birthdateError ? <div className="invalid-feedback">{birthdateError}</div> : null}
                                <span className="validity"/>
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <FormLabel>Place of Birth</FormLabel>
                                <Input type="text"
                                       placeholder="..."
                                       id="birthplace"
                                       name="birthplace"
                                       value={birthplace}
                                       onChange={onChange}
                                       error={ birthplaceError}
                                />
                                {birthplaceError ? <div className="invalid-feedback">{birthplaceError}</div> : null}

                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <FormLabel>Country</FormLabel>
                                <Input type="text"
                                       placeholder="..."
                                       id="birth_country"
                                       name="birth_country"
                                       value={birth_country}
                                       onChange={onChange}
                                       error={ birth_countryError}
                                />
                                {birth_countryError ? <div className="invalid-feedback">{birth_countryError}</div> : null}

                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>

                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        <Col md={8}>
                            <FormGroup>
                                <FormLabel>Business Address</FormLabel>
                                <Input type="text"
                                       placeholder="..."
                                       id="office_address"
                                       name="office_address"
                                       value={office_address}
                                       onChange={handleChangeAddress}
                                       error={office_addressError }

                                />
                                {office_addressError ? <div className="invalid-feedback">{office_addressError}</div> : null}

                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <FormLabel>Zip Code</FormLabel>
                                <Input type="text"
                                       placeholder="..."
                                       id="zipcode_office"
                                       name="zipcode_office"
                                       value={zipcode_office}
                                       error={ zipcode_officeError}
                                       onChange={handleChangeAddress}
                                />
                                {zipcode_officeError ? <div className="invalid-feedback">{zipcode_officeError}</div> : null}

                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3}>
                            <FormGroup>
                                <FormLabel>City</FormLabel>
                                <Input type="text"
                                       placeholder="..."
                                       id="office_city"
                                       name="office_city"
                                       value={office_city}
                                       onChange={handleChangeAddress}
                                       error={office_cityError }

                                />
                                {office_cityError ? <div className="invalid-feedback">{office_cityError}</div> : null}

                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <FormLabel>Country</FormLabel>
                                <Input type="text"
                                       placeholder="..."
                                       id="office_country"
                                       name="office_country"
                                       value={office_country}
                                       onChange={handleChangeAddress}
                                       error={office_countryError }

                                />
                                {office_countryError ? <div className="invalid-feedback">{office_countryError}</div> : null}

                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <FormLabel>Office Phone</FormLabel>
                                <Input type="tel"
                                       pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                                       placeholder="..."
                                       id="office_phone"
                                       name="office_phone"
                                       value={office_phone}
                                       onChange={handleChangeAddress}
                                       error={office_phoneError }

                                />
                                {office_phoneError ? <div className="invalid-feedback">{office_phoneError}</div> : null}

                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <FormLabel>Office Fax</FormLabel>
                                <Input type="tel"
                                       pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                                       placeholder="..."
                                       id="office_fax"
                                       name="office_fax"
                                       value={office_fax}
                                       //error={ office_faxError}
                                       onChange={handleChangeAddress}

                                />

                            </FormGroup>
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        <Col md={8}>
                            <FormGroup>
                                <FormLabel>Home Address</FormLabel>
                                <Input type="text"
                                       placeholder="..."
                                       id="home_address"
                                       name="home_address"
                                       value={home_address}
                                       error={ home_addressError}
                                       onChange={handleChangeAddress}

                                />
                                {home_addressError ? <div className="invalid-feedback">{home_addressError}</div> : null}

                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <FormLabel>Zip Code</FormLabel>
                                <Input type="text"
                                       placeholder="..."
                                       id="zipcode_home"
                                       name="zipcode_home"
                                       value={zipcode_home}
                                       error={zipcode_homeError }
                                       onChange={handleChangeAddress}

                                />
                                {zipcode_homeError ? <div className="invalid-feedback">{zipcode_homeError}</div> : null}

                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3}>
                            <FormGroup>
                                <FormLabel>City</FormLabel>
                                <Input type="text"
                                       placeholder="..."
                                       id="home_city"
                                       name="home_city"
                                       value={home_city}
                                       error={ home_cityError}
                                       onChange={handleChangeAddress}

                                />
                                {home_cityError ? <div className="invalid-feedback">{home_cityError}</div> : null}

                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <FormLabel>Country</FormLabel>
                                <Input type="text"
                                       placeholder="..."
                                       id="home_country"
                                       name="home_country"
                                       value={home_country}
                                       error={home_countryError }
                                       onChange={handleChangeAddress}

                                />
                                {home_countryError ? <div className="invalid-feedback">{home_countryError}</div> : null}

                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <FormLabel>Home Phone</FormLabel>
                                <Input type="tel"
                                       pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                                       placeholder="..."
                                       id="home_phone"
                                       name="home_phone"
                                       value={home_phone}
                                       error={home_phoneError}
                                       onChange={handleChangeAddress}

                                />
                                {home_phoneError ? <div className="invalid-feedback">{home_phoneError}</div> : null}

                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <FormLabel>Home Fax</FormLabel>
                                <Input type="tel"
                                       pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                                       placeholder="..."
                                       id="home_fax"
                                       name="home_fax"
                                       value={home_fax}
                                       //error={ home_faxError}
                                       onChange={handleChangeAddress}

                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        <Col md={8}>
                            <FormGroup>
                                <FormLabel>Email Address</FormLabel>
                                <Input type="email"
                                       placeholder="..."
                                       id="email"
                                       name="email"
                                       value={email}
                                       onChange={onChange}
                                       error={ emailError}

                                />
                                {emailError ? <div className="invalid-feedback">{emailError}</div> : null}

                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <FormLabel>Mobile</FormLabel>
                                <Input type="tel"
                                       pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                                       placeholder="..."
                                       id="mobile"
                                       name="mobile"
                                       value={mobile}
                                       onChange={onChange}
                                       error={mobileError }

                                />
                                {mobileError ? <div className="invalid-feedback">{mobileError}</div> : null}

                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3}>
                            <FormGroup>
                                <FormLabel>If I am not available, please contact</FormLabel>
                                <Row>
                                    <Col md={2}>
                                        <FormCheck
                                            type="radio"
                                            className="my-1 mr-sm-2"
                                            id="Mr"
                                            name="contact"
                                            value="Mr"
                                            label="Mr"
                                            checked={contact === 'Mr'}
                                            onChange={onChange}
                                        />
                                    </Col>
                                    <Col md={2}>
                                        <FormCheck
                                            type="radio"
                                            className="my-1 mr-sm-2"
                                            id="Ms"
                                            name="contact"
                                            value="Ms"
                                            label="Ms"
                                            checked={contact === 'Ms'}
                                            onChange={onChange}
                                        />
                                    </Col>
                                    <Col md={2}>
                                        <FormCheck
                                            type="radio"
                                            className="my-1 mr-sm-2"
                                            id="Dr"
                                            name="contact"
                                            value="Dr"
                                            label="Dr"
                                            checked={contact === 'Dr'}
                                            onChange={onChange}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                        </Col>
                        <Col md={5}>
                            <FormGroup>
                                <FormLabel>Contact Full Name</FormLabel>
                                <Input type="text"
                                       placeholder="..."
                                       id="contact_name"
                                       name="contact_name"
                                       value={contact_name}
                                       error={contact_nameError}
                                       onChange={onChange}

                                />
                                {contact_nameError ? <div className="invalid-feedback">{contact_nameError}</div> : null}

                            </FormGroup>
                        </Col>
                        <Col m={4}>
                            <FormGroup>
                                <FormLabel>Contact Mobile</FormLabel>
                                <Input type="tel"
                                       pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                                       placeholder="..."
                                       id="contact_mobile"
                                       name="contact_mobile"
                                       value={contact_mobile}
                                       onChange={onChange}
                                       error={contact_mobileError }

                                />
                                {contact_mobileError ? <div className="invalid-feedback">{contact_mobileError}</div> : null}
                            </FormGroup>
                        </Col>
                    </Row>
                </FormContent>
        </div>
    )
};

PersonalData.propTypes = {
    values: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
    error: PropTypes.any,

};

//export default PersonalData;

const StyledWrap = styled.div`
    text-align: center;
    height: 80px;
    background: -webkit-linear-gradient(left, #046b65, #06084a);
    color: #fff;
    font-weight: bold;
    line-height: 80px;
`;

const FormContent = styled.div`
    padding: 5%;
    border: 1px solid #ced4da;
`;
const StyledPhoto = styled.div`
    margin-right: 8%;
    @media (max-width: 1366px) {
    margin-right: 8%;
  }
  @media (max-width: 1024px) {
   margin-right: 4%;
  }
  @media (max-width: 768px) {
    margin-right: 0%;
  }
  @media (max-width: 375px) {
    margin-right: 0%;
  }
`;

const Input = styled.input`
	display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
`;
