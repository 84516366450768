import React from "react";
import styled from "styled-components";
import logo from "../../assets/JUNIA-ISEN-blanc.png";
import { Row, Col, FormLabel } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faInstagram,
    faTwitter,
    faFacebookF,
    faLinkedinIn,
    faYoutube,
} from "@fortawesome/free-brands-svg-icons";


const Footer = () => {
    return (
        <div>
            <StyledFoot>
                <StyledDiv>
                    <Row>
                        <Col md={{ span: 3, offset: 1 }}>
                            <StyledImage src={logo}/>
                        </Col>
                        <Col md={3}>
                            <div style={{paddingBottom:20}}>
                                <FormLabel><StyledText>Accueil : <StyledSpan> +33 (0)3 20 30 40 50</StyledSpan></StyledText></FormLabel>
                            </div>
                            <div>
                                <FormLabel><StyledSpan>Adresse :</StyledSpan> </FormLabel>
                            </div>
                            <div>
                                <FormLabel><StyledText>ISEN Lille – Institut Supérieur<br/>de Electronique et du Numérique<br/>41 boulevard Vauban<br/>59800 Lille Cedex</StyledText></FormLabel>
                            </div>
                        </Col>
                        <Col md={3}>
                            <MenuLinker href="https://www.isen-lille.fr/ecole-ingenieur-numerique/">L'ÉCOLE</MenuLinker>
                            <MenuLinker href="https://www.isen-lille.fr/formations/">FORMATIONS</MenuLinker>
                            <MenuLinker href="https://www.isen-lille.fr/admissions/">ADMISSIONS</MenuLinker>
                            <MenuLinker href="https://www.isen-lille.fr/mobilite-internationale/">MOBILITÉ INTERNATIONNALE</MenuLinker>
                            <MenuLinker href="https://www.isen-lille.fr/entreprises/">ENTREPRISES</MenuLinker>
                            <MenuLinker href="https://www.isen-lille.fr/ecole-ingenieur-numerique/projet-recherche/">LA RECHERCHE</MenuLinker>
                            <MenuLinker href="https://www.isen-lille.fr/contact/">CONTACT</MenuLinker>
                        </Col>
                        <Col md={2}>
                            <div>
                                <StyledBorder>
                                    <MenuLink href="https://www.instagram.com/isen.lille/">
                                        <FontAwesomeIcon icon={faInstagram} size="2x" />
                                    </MenuLink>
                                </StyledBorder>
                                <StyledBorder>
                                    <MenuLink href="https://twitter.com/isenlille">
                                        <FontAwesomeIcon icon={faTwitter} size="2x" />
                                    </MenuLink>
                                </StyledBorder>
                                <StyledBorder>
                                    <MenuLink href="https://www.facebook.com/ISEN.Lille/">
                                        <FontAwesomeIcon icon={faFacebookF} size="2x" />
                                    </MenuLink>
                                </StyledBorder>
                                <StyledBorder>
                                    <MenuLink href="https://www.facebook.com/ISEN.Lille/">
                                        <FontAwesomeIcon icon={faLinkedinIn} size="2x" />
                                    </MenuLink>
                                </StyledBorder>
                                <StyledBorder>
                                    <MenuLink href="https://www.youtube.com/channel/UCrTclv4lX7EOAMz5SKyiD8g?view_as=subscriber">
                                        <FontAwesomeIcon icon={faYoutube} size="2x" />
                                    </MenuLink>
                                </StyledBorder>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <StyledFooter>ISEN Lille, un programme "grande école" de <StyledFooterA className="link" href="https://www.junia.com/" target="_blank">JUNIA </StyledFooterA></StyledFooter>
                        </Col>
                    </Row>
                </StyledDiv>
                <FooterBottom>
                    <Col md={12}>
                        <div className="text-center">
                            <LinkBottom href="https://www.isen-lille.fr/mentions-legales/" target="_blank">Mentions légales</LinkBottom>
                            <LinkBottom href="https://www.isen-lille.fr/politique-donnees-personnelles/" target="_blank">Politique données personnelles</LinkBottom>
                        </div>
                    </Col>
                </FooterBottom>
            </StyledFoot>
        </div>
    );
};

const StyledFoot = styled.div`
    background-color: #006D68;
    position: relative;
    display: block;
    width: 100%;
    padding: 80px 0 0;
  @media (max-width: 768px) {
    display: none;
  }
  @media (max-width: 375px) {
    display: none;
  }
`;

const StyledDiv = styled.div`
margin-left: 10%;
margin-right: 10%;
`;
;
const StyledImage = styled.img`
    width: 100%;
    max-width: 200px;`;
const StyledSpan = styled.span`
    color:white;
    font-weight: 700;
    padding-bottom: 5px;`;
const StyledText = styled.span`
    color:white;
    font-weight: 300;
    padding-bottom: 5px;`;

const StyledFooter = styled.footer`
    position: relative;
    display: block;
    width: 100%;
    padding: 0 15px 40px;
    font-size: .875rem;
    line-height: 1rem;
    color: #fff;
    text-align: center;
     font-weight: 300;
    `;

const StyledFooterA = styled.a`
    color: #fff;
    text-decoration: none;
    outline: 0;
    border-bottom: 1px solid #fff;
    transition: all 300ms cubic-bezier(0.77, 0, 0.175, 1);
    &:hover{
        color: #ff5c38;
        border-bottom: 1px solid #DD3333;
        text-decoration: none;
    }
    `;

const MenuLink = styled.a`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 0.6rem;
    line-height: 0.125rem;
    color: #fff;
    text-indent: 0;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    transition: all 300ms cubic-bezier(0.77, 0, 0.175, 1);
    z-index: 4;
    &:hover{
        color: #ff5c38;
        text-decoration: none;
    }
    `;
const StyledBorder = styled.div`
    position: relative;
    display: block;
    width: 40px;
    height: 40px;
    border: 1px solid #ff5c38;
    border-radius: 50%;
    margin-bottom: 20px;
    text-indent: -9999px;
    outline: 0;
    text-decoration: none;
    transition: all 300ms cubic-bezier(0.77, 0, 0.175, 1);
`;

const MenuLinker = styled.a`
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 3px 0;
    text-decoration: none;
    color: white;
    font-weight:600;
    &:hover{
        color: #ff5c38;
        text-decoration: none;
        font-weight:600;
    }
    `;
const LinkBottom = styled.a`
    display: inline-block;
    font-size: .875rem;
    line-height: 2rem;
    font-weight: 400;
    color: #000;
    padding: 10px 10px;
    text-decoration: none;
    cursor: pointer;
    &:hover{
        color: #ff5c38;
        text-decoration: none;
        font-weight:400;
    }
    `;

const FooterBottom = styled.div`
    position: relative;
    display: block;
    width: 100%;
    background-color: #fff;
    `;
export default Footer;
