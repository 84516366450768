import AWS from "aws-sdk";

const spacesEndpoint = new AWS.Endpoint("ams3.digitaloceanspaces.com");

const s3 = new AWS.S3({
    endpoint: spacesEndpoint,
    accessKeyId: "AUGQIZNHX5BP7GV6VDLS",
    secretAccessKey: "9p9ur5ebLVULNvftb5mWUaF0Muqam/aZxmhB+Xslr9Y"
});
class UploadFilesService {

    upload(newFileName, file, /*onUploadProgress*/) {
        const config = {
            Body: file,
            Bucket: "nestoredu-cdn",
            Key: "documents/"+newFileName,
        };
        s3.putObject(config)
            .on("build", request => {
                request.httpRequest.headers.Host="https://nestoredu-cdn.ams3.digitaloceanspaces.com";
                request.httpRequest.headers["Content-Length"] = file.size;
                request.httpRequest.headers["Content-Type"] = "application/octet-stream";
                request.httpRequest.headers["x-amz-acl"] = "public-read";
                request.httpRequest.headers["Access-Control-Allow-Origin"] = "*";
            })
            .send((err) => {
                if (err) {
                    console.log("Failed to upload file", `${err}`);
                }
            });

    }

}

export default new UploadFilesService();
