import React from 'react';
import styled from "styled-components";
import { Row, Col, FormGroup , FormControl, FormLabel} from "react-bootstrap";
import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import {Button, Container, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

const data_work = [
];

class WorkExperience extends React.Component{

    state = {
        data_work: data_work,
        modalUpdate_work: false,
        modalInsert_work: false,
        form_work: {
            id: "",
            from_experience: "",
            to_experience: "",
            organisation_experience: "",
            position_experience: "",
            responsibility_experience: "",
        },
    };
    showModalUpdate_work = (work) => {
        this.setState({
            form_work: work,
            modalUpdate_work: true,
        });
    };
    closeModalUpdate_work = () => {
        this.setState({ modalUpdate_work: false });
    };
    showModalInsert_work = () => {
        this.setState({
            modalInsert_work: true,
        });
    };
    closeModalInsert_work = () => {
        this.setState({ modalInsert_work: false });
    };
    edit_work = (work) => {
        let counter = 0;
        let array = this.state.data_work;
        // eslint-disable-next-line array-callback-return
        array.map((register) => {
            if (work.id === register.id) {
                array[counter].from_experience = work.from_experience;
                array[counter].to_experience = work.to_experience;
                array[counter].organisation_experience = work.organisation_experience;
                array[counter].position_experience = work.position_experience;
                array[counter].responsibility_experience = work.responsibility_experience;

            }
            counter++;
        });
        this.setState({ data_work: array, modalUpdate_work: false });
    };
    delete_work = (work) => {
        const option = window.confirm("Are you sure you want to delete the item "+work.id);
        if (option === true) {
            let counter = 0;
            let array = this.state.data_work;
            // eslint-disable-next-line array-callback-return
            array.map((register) => {
                if (work.id === register.id) {
                    array.splice(counter, 1);
                }
                counter++;
            });
            this.setState({ data_work: array, modalUpdate_work: false });
        }
    };
    insert_work= ()=>{
        let valueNew= {...this.state.form_work};
        valueNew.id=this.state.data_work.length+1;
        let list_work= this.state.data_work;
        list_work.push(valueNew);
        this.props.setModalFormWorkExperience(list_work);
        this.setState({ modalInsert_work: false, data_work: list_work });
    }
    handleChange = (e) => {
        // console.log(e.target.value);
        this.setState({
            form_work:{
                ...this.state.form_work,
                [e.target.name]: e.target.value,
            }
        });
    };
    render(){
        const { values, handleChangeWork, errors}  = this.props
        const { experience_work, experience_management,current_employer, current_employer_activity,job_title,
            experience_company, subordinate_number, responsibilities } = values;
        const { experience_work: experience_workError, experience_management: experience_managementError,current_employer: current_employerError,
                current_employer_activity: current_employer_activityError,job_title: job_titleError,
                experience_company: experience_companyError, subordinate_number:
                subordinate_numberError, responsibilities: responsibilitiesError } = errors;
        return (
            <div>
                <StyledWrap>
                <h2> B. Work Experience</h2>
            </StyledWrap>
                <FormContent>
                    <Row>
                    <Col md={6}>
                        <FormGroup>
                            <FormLabel>Full-time work experience</FormLabel>
                            <FormControl as="select"  id="experience_work" name="experience_work"
                                         value={experience_work}
                                         error={experience_workError}
                                         onChange={handleChangeWork} >
                                <option value="">Select Year of Experience</option>
                                <option value="0-1 years">0-1 years</option>
                                <option value="2-3 years">2-3 years</option>
                                <option value="4-5 years">4-5 years</option>
                                <option value="6-7 years">6-7 years</option>
                                <option value="8-9 years">8-9 years</option>
                                <option value="10-11 years">10-11 years</option>
                                <option value="11-12 years">11-12 years</option>
                                <option value="13-14 years">13-14 years</option>
                                <option value="15-16 years">15-16 years</option>
                                <option value="17-18 years">17-18 years</option>
                                <option value="19-20 years">19-20 years</option>
                                <option value="21 or more years">21 or more years</option>
                            </FormControl>
                            {experience_workError ? <div className="invalid-feedback">{experience_workError}</div> : null}
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <FormLabel>Management experience</FormLabel>
                            <FormControl as="select"  id="experience_management" name="experience_management"
                                         value={experience_management}
                                         error={experience_managementError}

                                         onChange={handleChangeWork} >
                                <option value="">Select Year of Experience</option>
                                <option value="0-1 years">0-1 years</option>
                                <option value="2-3 years">2-3 years</option>
                                <option value="4-5 years">4-5 years</option>
                                <option value="6-7 years">6-7 years</option>
                                <option value="8-9 years">8-9 years</option>
                                <option value="10-11 years">10-11 years</option>
                                <option value="11-12 years">11-12 years</option>
                                <option value="13-14 years">13-14 years</option>
                                <option value="15-16 years">15-16 years</option>
                                <option value="17-18 years">17-18 years</option>
                                <option value="19-20 years">19-20 years</option>
                                <option value="21 or more years">21 or more years</option>
                            </FormControl>
                            {experience_managementError ? <div className="invalid-feedback">{experience_managementError}</div> : null}
                        </FormGroup>
                    </Col>
                    </Row>
                    <Row>
                    <Col md={6}>
                        <FormGroup>
                            <FormLabel>Work Experience-Current : Employer</FormLabel>
                            <Input type="text"
                                   placeholder="..."
                                   id="current_employer"
                                   name="current_employer"
                                   value={current_employer}
                                   onChange={handleChangeWork}
                                   error={current_employerError}

                            />
                            {current_employerError ? <div className="invalid-feedback">{current_employerError}</div> : null}
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <FormLabel>Nature of Employer's Business or Activity</FormLabel>
                            <Input type="text"
                                   placeholder="..."
                                   id="current_employer_activity"
                                   name="current_employer_activity"
                                   value={current_employer_activity}
                                   onChange={handleChangeWork}
                                   error={current_employer_activityError}

                            />
                            {current_employer_activityError ? <div className="invalid-feedback">{current_employer_activityError}</div> : null}
                        </FormGroup>
                    </Col>
                    </Row>
                    <Row>
                    <Col md={12}>
                        <FormGroup>
                            <FormLabel>Job Title</FormLabel>
                            <Input type="text"
                                   placeholder="..."
                                   id="job_title"
                                   name="job_title"
                                   value={job_title}
                                   onChange={handleChangeWork}
                                   error={job_titleError}

                            />
                            <h6>If you have had several positions within your current organization, it is
                                only necessary to give information for your current position
                            </h6>
                            {job_titleError ? <div className="invalid-feedback">{job_titleError}</div> : null}
                        </FormGroup>
                    </Col>
                    </Row>
                    <Row>
                    <Col md={6}>
                        <FormGroup>
                            <FormLabel>Length of Time in position</FormLabel>
                            <FormControl as="select"  id="experience_company" name="experience_company"
                                         value={experience_company}
                                         error={experience_companyError}
                                         onChange={handleChangeWork} >
                                <option value="">Select Year of Experience</option>
                                <option value="0-1 years">0-1 years</option>
                                <option value="2-3 years">2-3 years</option>
                                <option value="4-5 years">4-5 years</option>
                                <option value="6-7 years">6-7 years</option>
                                <option value="8-9 years">8-9 years</option>
                                <option value="10-11 years">10-11 years</option>
                                <option value="11-12 years">11-12 years</option>
                                <option value="13-14 years">13-14 years</option>
                                <option value="15-16 years">15-16 years</option>
                                <option value="17-18 years">17-18 years</option>
                                <option value="19-20 years">19-20 years</option>
                                <option value="21 or more years">21 or more years</option>
                            </FormControl>
                            {experience_companyError ? <div className="invalid-feedback">{experience_companyError}</div> : null}
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <FormLabel>Number of Subordinates</FormLabel>
                            <Input type="number"
                                   pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                                   placeholder="..."
                                   id="subordinate_number"
                                   name="subordinate_number"
                                   value={subordinate_number}
                                   onChange={handleChangeWork}
                                   error={subordinate_numberError}

                            />
                            <h6>If subsidiary, please provide the figure of subsidiary</h6>
                            {subordinate_numberError ? <div className="invalid-feedback">{subordinate_numberError}</div> : null}
                        </FormGroup>
                    </Col>
                    </Row>
                    <Row>
                    <Col md={10}>
                        <FormGroup>
                            <FormLabel>Describe your present duties and responsibilities</FormLabel>
                            <Textarea type="text"
                                      className="form-control"
                                      name="responsibilities"
                                      placeholder="..."
                                      value={responsibilities}
                                      onChange={handleChangeWork}
                                      error={responsibilitiesError}
                                      aria-describedby="basic-addon1"
                                      id="responsibilities"
                                      rows="6"

                            />
                            {responsibilitiesError ? <div className="invalid-feedback">{responsibilitiesError}</div> : null}
                        </FormGroup>
                    </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                        <h6 className="text-center">Please list your full-time employment experience in
                            reverse chronological order <br/>
                            (If necessary, please use additional slots)</h6>
                        <>
                            <Container>
                                <br/>
                                <Button className="button-add" onClick={() => this.showModalInsert_work()}>Add</Button>
                                <br/>
                                <br/>
                                <table className="table table-bordered text-center">
                                    <thead>
                                    <tr className="tire">
                                        <th>N°</th>
                                        <th>From(mm/yy)</th>
                                        <th>To(mm/yy)</th>
                                        <th>Organization</th>
                                        <th>Position</th>
                                        <th>Responsibility</th>
                                        <th>Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.data_work.map((work) => (
                                        <tr key={work.id}>
                                            <td>{work.id}</td>
                                            <td>{work.from_experience}</td>
                                            <td>{work.to_experience}</td>
                                            <td>{work.organisation_experience}</td>
                                            <td>{work.position_experience}</td>
                                            <td>{work.responsibility_experience}</td>
                                            <td>
                                                <Button color="primary"
                                                        onClick={() => this.showModalUpdate_work(work)}>Edit</Button>
                                                {" "}
                                                <Button color="danger"
                                                        onClick={() => this.delete_work(work)}>delete</Button>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </Container>

                            <Modal isOpen={this.state.modalUpdate_work}>
                                <ModalHeader>
                                    <div><h3>Edit Information</h3></div>
                                </ModalHeader>

                                <ModalBody>
                                    <FormGroup>
                                        <label>
                                            No°:
                                        </label>

                                        <input
                                            className="form-control"
                                            readOnly
                                            type="text"
                                            value={this.state.form_work.id}
                                        />
                                    </FormGroup>

                                    <FormGroup>
                                        <label>
                                            From(mm/yy):
                                        </label>
                                        <input
                                            className="form-control"
                                            name="from_experience"
                                            type="month"
                                            onChange={this.handleChange}
                                            value={this.state.form_work.from_experience}
                                        />
                                    </FormGroup>

                                    <FormGroup>
                                        <label>
                                            To(mm/yy):
                                        </label>
                                        <input
                                            className="form-control"
                                            name="to_experience"
                                            type="month"
                                            onChange={this.handleChange}
                                            value={this.state.form_work.to_experience}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <label>
                                            Organization:
                                        </label>
                                        <input
                                            className="form-control"
                                            name="organisation_experience"
                                            type="text"
                                            onChange={this.handleChange}
                                            value={this.state.form_work.organisation_experience}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <label>
                                            Position:
                                        </label>
                                        <input
                                            className="form-control"
                                            name="position_experience"
                                            type="text"
                                            onChange={this.handleChange}
                                            value={this.state.form_work.position_experience}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <label>
                                            Responsibility:
                                        </label>
                                        <input
                                            className="form-control"
                                            name="responsibility_experience"
                                            type="text"
                                            onChange={this.handleChange}
                                            value={this.state.form_work.responsibility_experience}
                                        />
                                    </FormGroup>
                                </ModalBody>

                                <ModalFooter>
                                    <Button
                                        color="primary"
                                        onClick={() => this.edit_work(this.state.form_work)}
                                    >
                                        Update
                                    </Button>
                                    <Button
                                        color="danger"
                                        onClick={() => this.closeModalUpdate_work()}
                                    >
                                        Cancel
                                    </Button>
                                </ModalFooter>
                            </Modal>
                            <Modal isOpen={this.state.modalInsert_work}>
                                <ModalHeader>
                                    <div><h3>Insert Information</h3></div>
                                </ModalHeader>
                                <ModalBody>
                                    <FormGroup>
                                        <label>
                                            N°:
                                        </label>

                                        <input
                                            className="form-control"
                                            readOnly
                                            type="text"
                                            value={this.state.data_work.length + 1}
                                        />
                                    </FormGroup>

                                    <FormGroup>
                                        <label>
                                            From(mm/yy):
                                        </label>
                                        <input
                                            className="form-control"
                                            name="from_experience"
                                            type="month"
                                            onChange={this.handleChange}
                                        />
                                    </FormGroup>

                                    <FormGroup>
                                        <label>
                                            To(mm/yy):
                                        </label>
                                        <input
                                            className="form-control"
                                            name="to_experience"
                                            type="month"
                                            onChange={this.handleChange}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <label>
                                            Organization:
                                        </label>
                                        <input
                                            className="form-control"
                                            name="organisation_experience"
                                            type="text"
                                            onChange={this.handleChange}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <label>
                                            Position:
                                        </label>
                                        <input
                                            className="form-control"
                                            name="position_experience"
                                            type="text"
                                            onChange={this.handleChange}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <label>
                                            Responsibility:
                                        </label>
                                        <input
                                            className="form-control"
                                            name="responsibility_experience"
                                            type="text"
                                            onChange={this.handleChange}
                                        />
                                    </FormGroup>
                                </ModalBody>
                                <ModalFooter>
                                    <Button
                                        color="primary"
                                        onClick={() => this.insert_work()}
                                    >
                                        Insert
                                    </Button>
                                    <Button
                                        className="btn btn-danger"
                                        onClick={() => this.closeModalInsert_work()}
                                    >
                                        Cancel
                                    </Button>
                                </ModalFooter>
                            </Modal>
                        </>
                    </Col>
                    </Row>
                </FormContent>
        </div>
        );
    }
}

WorkExperience.propTypes = {
    values: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
    error: PropTypes.any,
};

export default WorkExperience;

const StyledWrap = styled.div`
    text-align: center;
    height: 80px;
    background: -webkit-linear-gradient(left, #046b65, #06084a);
    color: #fff;
    font-weight: bold;
    line-height: 80px;
`;

const FormContent = styled.div`
    padding: 5%;
    border: 1px solid #ced4da;
`;

const Input = styled.input`
	display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
`;

const Textarea = styled.textarea`
	display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
`;
