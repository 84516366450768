import React from 'react';
import styled from "styled-components";
import {Row, Col, FormGroup, FormLabel, FormCheck} from "react-bootstrap";
import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap.min.css';


export default function AdditionalInformation(props) {
    const { values, errors , handleChangeAddition } = props;
//const AdditionalInformation = ({ values, errors, onChange , handleChangeAddition}) => {
    // console.log(values);
    const {org_sponsor, judgment , circumstances } = values.addition;
    const {circumstances: circumstancesError } = errors;

    //console.log(org_sponsor);
    return (
        <div>
            <StyledWrap>
                <h2> D. Additional Information</h2>
            </StyledWrap>
            <FormContent>
                <Row>
                    <Col md={12}>
                        <FormGroup>
                            <FormLabel htmlFor="name">Will your organization Sponsor your tuition
                                fee?</FormLabel>
                            <Row>
                                <Col md={3}>
                                    <FormCheck
                                        type="radio"
                                        className="my-1 mr-sm-2"
                                        id="Full"
                                        name="org_sponsor"
                                        value="Full"
                                        label="Full"
                                        checked={org_sponsor === 'Full'}
                                        onChange={handleChangeAddition}
                                    />
                                </Col>
                                <Col md={3}>
                                    <FormCheck
                                        type="radio"
                                        className="my-1 mr-sm-2"
                                        id="In Part"
                                        name="org_sponsor"
                                        value="In Part"
                                        label="In Part"
                                        checked={org_sponsor === 'In Part'}
                                        onChange={handleChangeAddition}
                                    />
                                </Col>
                                <Col md={3}>
                                    <FormCheck
                                        type="radio"
                                        className="my-1 mr-sm-2"
                                        id="Self-sponsored"
                                        name="org_sponsor"
                                        value="Self-sponsored"
                                        label="Self-sponsored"
                                        checked={org_sponsor === 'Self-sponsored'}
                                        onChange={handleChangeAddition}
                                    />
                                </Col>
                            </Row>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <FormGroup>
                            <FormLabel htmlFor="name">Have you ever been convicted of, or pled guilty to, a
                                felony or a misdemeanor in any country?</FormLabel>
                            <Row>
                                <Col md={3}>
                                    <FormCheck
                                        type="radio"
                                        className="my-1 mr-sm-2"
                                        id="No"
                                        name="judgment"
                                        value="No"
                                        label="No"
                                        checked={judgment === 'No'}
                                        onChange={handleChangeAddition}
                                    />
                                </Col>
                                <Col md={3}>
                                    <FormCheck
                                        type="radio"
                                        className="my-1 mr-sm-2"
                                        id="Yes"
                                        name="judgment"
                                        value="Yes"
                                        label="Yes"
                                        checked={judgment === 'Yes'}
                                        onChange={handleChangeAddition}
                                    />
                                </Col>
                                <Col md={6}>
                                    <Textarea type="text"
                                              name="circumstances"
                                              className="form-control"
                                              placeholder="Please explain the circumstances"
                                              aria-describedby="basic-addon1"
                                              id="circumstances"
                                              value={circumstances}
                                              onChange={handleChangeAddition}
                                              error={circumstancesError}
                                              rows="8"/>
                                    {circumstancesError ? <div className="invalid-feedback">{circumstancesError}</div> : null}

                                </Col>
                            </Row>
                        </FormGroup>
                    </Col>
                </Row>
            </FormContent>
        </div>
    );
};

AdditionalInformation.propTypes = {
    values: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    handleChangeAddition: PropTypes.func.isRequired,
    error: PropTypes.any,
};

//export default AdditionalInformation;

const StyledWrap = styled.div`
    text-align: center;
    height: 80px;
    background: -webkit-linear-gradient(left, #046b65, #06084a);
    color: #fff;
    font-weight: bold;
    line-height: 80px;
`;

const FormContent = styled.div`
    padding: 5%;
    border: 1px solid #ced4da;
`;

const Textarea = styled.textarea`
	display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
`;
