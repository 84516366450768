import React from 'react';
import styled from "styled-components";
import {Row, Col, FormLabel, FormFile} from "react-bootstrap";
import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap.min.css';

//const FileToProvide = ({ values, onChange }) => {
    export default function FileToProvide(props) {
        const {values, handleChangeFile, onChange, errors} = props;
        const {curriculum_vitae,graduate_degree,master_business_admin,
            passport,other_file,other_file_1,dateSignature} = values;
        const { curriculum_vitae: curriculum_vitaeError, passport: passportError} = errors;
        const today = new Date();
        const todayDate = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();

        // on ne conserve les fonctions ci-dessous que pour donner des noms aux fichiers, car on utilise en réalité le handleChangeFile()
        // a voir pour renommer/simplifier les noms aussi
        const onChangeCurriculumVitae = event => {
            const body = { target: { name: 'curriculum_vitae', value: event.target.files[0] } };
            handleChangeFile(body);
        };
        const onChangeGraduateDegree = event => {
            const body = { target: { name: 'graduate_degree', value: event.target.files[0] } };
            handleChangeFile(body);
        };
        const onChangeMasterBusinessAdmin = event => {
            const body = { target: { name: 'master_business_admin', value: event.target.files[0] } };
            handleChangeFile(body);
        };
        const onChangePassport = event => {
            const body = { target: { name: 'passport', value: event.target.files[0] } };
            handleChangeFile(body);
        };
        const onChangeOtherFile = event => {
            const body = { target: { name: 'other_file', value: event.target.files[0] } };
            handleChangeFile(body);
        };
        const onChangeOtherFile1 = event => {

            const body = { target: { name: 'other_file_1', value: event.target.files[0] } };
            handleChangeFile(body);

        };

        return (
            <div>
                <StyledWrap>
                    <h2> E. File to provide</h2>
                </StyledWrap>
                <FormContent>
                    <Row>
                        <Col md={6}>
                            <StyledWrapper controlId='uploadFormId'>
                                <LabelTitle>Curriculum Vitae :</LabelTitle>
                                <FormFile
                                    id="curriculum_vitae"
                                    accept=".doc,.docx, .pdf"
                                    value={curriculum_vitae}
                                    onChange={onChangeCurriculumVitae}
                                    error={curriculum_vitaeError}
                                />
                                {curriculum_vitaeError && <div className="invalid-feedback">{curriculum_vitaeError}</div>}

                            </StyledWrapper>
                        </Col>
                        <Col md={6}>
                            <StyledWrapper controlId='uploadFormId'>
                                <LabelTitle>Graduate Degree :</LabelTitle>
                                <FormFile
                                    id="graduate_degree"
                                    value={graduate_degree}
                                    accept=".doc,.docx, .pdf"
                                    onChange={onChangeGraduateDegree}
                                />
                            </StyledWrapper>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <StyledWrapper controlId='uploadFormId'>
                                <LabelTitle>Master of Business Administration :</LabelTitle>
                                <FormFile
                                    id="master_business_admin"
                                    value={master_business_admin}
                                    accept=".doc,.docx, .pdf"
                                    onChange={onChangeMasterBusinessAdmin}
                                />
                            </StyledWrapper>
                        </Col>
                        <Col md={6}>
                            <StyledWrapper controlId='uploadFormId'>
                                <LabelTitle>Copy of Passport :</LabelTitle>
                                <FormFile
                                    id="passport"
                                    value={passport}
                                    accept=".doc,.docx, .pdf"
                                    onChange={onChangePassport}
                                    error={passportError}
                                />
                                {passportError && <div className="invalid-feedback">{passportError}</div>}
                            </StyledWrapper>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <StyledWrapper controlId='uploadFormId'>
                                <LabelTitle>Other File :</LabelTitle>
                                <FormFile
                                    id="other_file"
                                    value={other_file}
                                    accept=".doc,.docx, .pdf"
                                    onChange={onChangeOtherFile}
                                />
                            </StyledWrapper>
                        </Col>
                        <Col md={6}>
                            <StyledWrapper controlId='uploadFormId'>
                                <LabelTitle>Other File :</LabelTitle>
                                <FormFile
                                    id="other_file_1"
                                    value={other_file_1}
                                    onChange={onChangeOtherFile1}
                                />
                            </StyledWrapper>
                        </Col>
                    </Row>
                    <hr className="line"/>
                    <Col md={12}>
                        <h3 className="text-center">Declaration Letter</h3>
                        <p>I hereby declare that all the information given in this application,
                            including that in the supplementary documents, is, to the best of my knowledge,
                            accurate and complete; and I agree to provide original certificate(s) for
                            verification when required.
                            I further certify that all the essays are entirely and exclusively my own work and
                            that no documents
                            have been falsified. I am aware that any misrepresentation or material omission in
                            my application will
                            result in the denial of admission or the cancellation of student status, and no
                            refund of fees paid.</p>
                        <p>I understand that materials received by ISEN by JUNIA in connection with this
                            application become
                            the property of ISEN by JUNIA; as such, none of the materials are returnable. I
                            authorize ISEN
                            by JUNIA to check on records of my previous studies at other institutions.</p>
                    </Col>
                    <Row>
                        <Col md={6}>
                        </Col>
                        <FormLabel column md={2} htmlFor="bday">Date :</FormLabel>
                        <Col md={4}>
                            <Input type="text" name="dateSignature" id="dateSignature"
                                   disabled
                                   className="form-control"
                                   value={dateSignature}
                                   currentDate={todayDate}
                                   onChange={onChange}
                            />
                            <span className="validity"/>
                        </Col>
                    </Row>
                </FormContent>
            </div>
        );
    };


FileToProvide.propTypes = {
    values: PropTypes.object.isRequired,
    onChange: PropTypes.object.isRequired,
    handleChangeFile: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    error: PropTypes.any,
};

//export default FileToProvide;

const StyledWrap = styled.div`
    text-align: center;
    height: 80px;
    background: -webkit-linear-gradient(left, #046b65, #06084a);
    color: #fff;
    font-weight: bold;
    line-height: 80px;
`;

const FormContent = styled.div`
    padding: 5%;
    border: 1px solid #ced4da;
`;

const StyledWrapper = styled.div`
    margin:5%;

`;
const LabelTitle = styled.h5`
`;

const Input = styled.input`
	display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
`;
