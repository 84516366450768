import React from "react";
import logo from "../../assets/JUNIA-ISEN-300x89.jpg";
import styled from "styled-components";

//import "./index.css";

const Header = (props) => {
    //console.log("Header -> props", props);
    return (
        <StyledDiv className="App-header">
            <StyledLogo src={logo} alt="Logo" />
        </StyledDiv>
    );
};

const StyledDiv = styled.div`
background: white;
`;
const StyledLogo = styled.img`
  width:10%;
  position: relative;
  transform: translate(0%, 5%);
`;

export default Header;
