import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";
import './index.css'

export default function NavigationBottom({
                                             currentStep,
                                             previousStep,
                                             nextPage,
                                             onSubmit,
                                         }) {
    return (
        <StyledDivWrap>
            <div className="d-flex justify-content-center">
                {currentStep < 4 && (
                    <>
                        <button
                            type="button"
                            className="btn btn-light mr-4 slide_left"
                            disabled={!currentStep}
                            onClick={previousStep}
                        >
                            Previous
                        </button>
                        <button
                            type="button"
                            className="btn btn-secondary slide_left"
                            onClick={nextPage}
                        >
                            Next
                        </button>
                    </>
                )}

                {currentStep === 4 && (
                    <>
                    <button
                        type="button"
                        className="btn btn-light mr-4 slide_left"
                        disabled={!currentStep}
                        onClick={previousStep}
                    >
                        Previous
                    </button>
                    <button type="button" className="btn slide_right" value={onSubmit} onClick={onSubmit}>
                        SEND
                    </button>
                    </>
                )}
            </div>
        </StyledDivWrap>
    );
}

NavigationBottom.propTypes = {
    currentStep: PropTypes.number.isRequired,
    previousStep: PropTypes.func.isRequired,
    nextPage: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

const StyledDivWrap = styled.div`
  margin-top: 2%;
`;


