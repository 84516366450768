import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './index.css';



const steps = ['Personal Data', 'Work Experience', 'Education Background', 'Additional Information', 'File to provide'];

const StepNavigation = ({ currentStep }) => {
    const mainClassName = (index, currentStep) => {
        return classNames('step', {
            'is-active': index === currentStep ,
            'is-completed': index < currentStep,
        });
    }

    const handleClick = (index) => this.setState({ currentStep: index })

    return (
        <div  className="steps mb-4">
            {steps.map((title, index) => {
                return (
                    <div className={mainClassName(index, currentStep)} key={index}>
                        <button className="step__marker" onClick={handleClick} >{index + 1}</button>
                        <div className="step__title mt-1">{title}</div>
                    </div>
                );
            })}
        </div>
    );
};

StepNavigation.propTypes = {
    currentStep: PropTypes.number.isRequired,
};

export default StepNavigation;

